// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "B_t9 d_gv d_cs";
export var heroHeaderCenter = "B_gw d_gw d_cs d_dw";
export var heroHeaderRight = "B_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "B_vb d_gr d_cw";
export var heroParagraphCenter = "B_gs d_gs d_cw d_dw";
export var heroParagraphRight = "B_gt d_gt d_cw d_dx";
export var heroSliderBtnWrapperLeft = "B_vj d_d2 d_d1 d_w d_bz d_bG d_bJ";
export var heroSliderBtnWrapperCenter = "B_vk d_d3 d_d1 d_w d_bz d_bD d_bJ";
export var heroSliderBtnWrapperRight = "B_vl d_d4 d_d1 d_w d_bz d_bH d_bJ";
export var btnWrapper = "B_d2";
export var wrapper = "B_qm d_bz d_bP d_w d_H";
export var background = "B_ng d_bd d_0 d_8 d_7 d_4 d_9";
export var frameWrapper = "B_vm d_w d_H d_Z d_4 d_7 d_bk d_bf d_bv";
export var videoFrame = "B_vn d_0";
export var videoOverlay = "B_vp d_w d_H d_0 d_4";
export var arrow = "B_kG d_0 d_n";
export var arrowLeft = "B_vq B_kG d_0 d_n";
export var arrowRight = "B_vr B_kG d_0 d_n";
export var buttonsWrapper = "B_vs d_bz d_bP";
export var dotsWrapper = "B_vt d_bz d_bP d_bD d_0";
export var dot = "B_vv d_bx d_dB";
export var dotActive = "B_vw B_vv d_bx d_dB";
export var heroSliderBackgroundImage = "B_gz d_w d_H d_bR d_bk";
export var loadingWrapper = "B_vx d_bd d_0 d_8 d_7 d_4 d_9";
export var topAlt = "B_gC d_gC d_gB d_bz d_bP d_w";
export var top = "B_gD d_gD d_gB d_bz d_bP d_w";
export var bottomAlt = "B_gG d_gG d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var bottom = "B_gH d_gH d_bd d_0 d_8 d_7 d_4 d_9 d_gB d_bz d_bP";
export var heroExceptionSmall = "B_r9 t_r9";
export var heroExceptionNormal = "B_sb t_sb";
export var heroExceptionLarge = "B_sc t_sc";