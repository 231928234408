// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_qM d_bD";
export var datasheetRowWrapper = "q_lt d_lt d_bK";
export var datasheetLeftWrapper = "q_qN d_bz d_bP";
export var datasheetWrapperFull = "q_qP d_cD";
export var datasheetWrapperFullLeft = "q_pf d_cD d_bz d_bP d_bD d_bT";
export var contentWrapper = "q_mv d_lv";
export var contentWrapperCenter = "q_qQ d_lz";
export var datasheetLeftWrapperCenter = "q_qR d_lx d_bz d_bD d_bP d_bJ";
export var datasheetImageCenterWrapper = "q_lr d_lr d_w d_cv";
export var datasheetRightWrapperCenter = "q_qS d_lw";
export var datasheetFooter = "q_ly d_ly";
export var alignLeft = "q_qh d_dv";
export var alignCenter = "q_bP d_dw";
export var alignRight = "q_qj d_dx";
export var datasheetInnerWrapperNewLeft = "q_qT d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetInnerWrapperLeft = "q_qV d_fd d_P d_Z d_bz d_bP d_b5 d_dj d_c9";
export var datasheetInnerWrapperRight = "q_qW d_ff d_P d_Z d_bz d_bP d_dj";
export var datasheetRowWrapperReverse = "q_qX d_bK";
export var datasheetSubtitle = "q_qY d_cx";
export var tableRow = "q_qZ";
export var cell = "q_q0";
export var tableRowWrapper = "q_q1 d_w d_dv";
export var tableHeadingCell = "q_q2";
export var tableHeading = "q_q3";
export var tableRowStriped = "q_q4";
export var tableRowHeading = "q_q5";
export var dataTable = "q_q6 d_w";
export var imageWrapper = "q_qB d_fg d_Z";
export var imageWrapperFull = "q_q7 d_H d_w d_bf d_Z";
export var imageWrapperIcon = "q_q8";
export var titleMargin = "q_q9 d_cs";
export var datasheetInnerInnerWrapper = "q_rb d_w";
export var hasLabels = "q_rc";
export var label = "q_rd";
export var SmallSmall = "q_qC t_qC t_rs t_rQ";
export var SmallNormal = "q_qD t_qD t_rs t_rR";
export var SmallLarge = "q_qF t_qF t_rs t_rN";