// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "z_t9 d_gv d_cs";
export var heroHeaderCenter = "z_gw d_gw d_cs d_dw";
export var heroHeaderRight = "z_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "z_vb d_gr d_cw";
export var heroParagraphCenter = "z_gs d_gs d_cw d_dw";
export var heroParagraphRight = "z_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "z_vc d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "z_vd d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "z_vf d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "z_vg d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "z_vh d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "z_r9 t_r9";
export var heroExceptionNormal = "z_sb t_sb";
export var heroExceptionLarge = "z_sc t_sc";
export var Title1Small = "z_rY t_rY t_rs t_rt";
export var Title1Normal = "z_rZ t_rZ t_rs t_rv";
export var Title1Large = "z_r0 t_r0 t_rs t_rw";
export var BodySmall = "z_rn t_rn t_rs t_rM";
export var BodyNormal = "z_rp t_rp t_rs t_rN";
export var BodyLarge = "z_rq t_rq t_rs t_rP";