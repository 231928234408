// extracted by mini-css-extract-plugin
export var sectionMediaWrapper = "x_tR d_bz d_cs";
export var alignLeft = "x_qh d_fp d_bG d_dv";
export var alignCenter = "x_bP d_fq d_bD d_dw";
export var alignRight = "x_qj d_fr d_bH d_dx";
export var alignColumnLeft = "x_tS d_fs d_bN d_dv";
export var alignColumnCenter = "x_tT d_ft d_bP d_dw";
export var alignColumnRight = "x_tV d_fv d_bQ d_dx";
export var featuresContainer = "x_tW d_dW";
export var featuresContainerFull = "x_tX d_dT";
export var featuresComponentWrapper = "x_hP d_hP d_cv";
export var compContentWrapper = "x_tY d_hQ d_c7 d_H";
export var featuresTextBlockWrapper = "x_hZ d_hZ";
export var featuresMainHeader = "x_hM d_hM d_w d_cr";
export var featuresSubHeader = "x_hN d_hN d_w";
export var featuresComponentHeader = "x_hR d_hR d_w d_cr";
export var featuresComponentParagraph = "x_hX d_hX d_w";
export var featuresComponentWrapperRow = "x_tZ d_H";
export var featuresBlockWrapper = "x_hY d_hY";
export var btnWrapper = "x_d2 d_bz d_ck";
export var btnWrapperCards = "x_t0 d_bz d_cd";
export var cardsWrapper = "x_t1";