// extracted by mini-css-extract-plugin
export var alignLeft = "r_qh d_bG";
export var alignCenter = "r_bP d_bD";
export var alignRight = "r_qj d_bH";
export var textAlignLeft = "r_rf";
export var textAlignCenter = "r_rg";
export var textAlignRight = "r_rh";
export var embedInnerWrapperDesign1 = "r_rj d_bM";
export var embed = "r_rk d_b1";
export var titleMargin = "r_q9 d_cw d_dw";
export var subtitleMargin = "r_rl d_cs d_dw";
export var paragraphMargin = "r_rm d_cw d_dw";
export var SubtitleSmall = "r_qd t_qd t_rs t_rF";
export var SubtitleNormal = "r_qf t_qf t_rs t_rG";
export var SubtitleLarge = "r_qg t_qg t_rs t_rH";
export var BodySmall = "r_rn t_rn t_rs t_rM";
export var BodyNormal = "r_rp t_rp t_rs t_rN";
export var BodyLarge = "r_rq t_rq t_rs t_rP";