// extracted by mini-css-extract-plugin
export var customText = "p_qG d_dv d_cs d_cg";
export var videoIframeStyle = "p_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "p_qH d_cs d_cg d_Z";
export var customRow = "p_qb d_bD d_bf";
export var quoteWrapper = "p_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "p_pX d_H";
export var masonryImageWrapper = "p_p3";
export var title = "p_qr";
export var Title3Small = "p_qJ t_qJ t_rs t_rB";
export var Title3Normal = "p_qK t_qK t_rs t_rC";
export var Title3Large = "p_qL t_qL t_rs t_rD";