// extracted by mini-css-extract-plugin
export var galleryMasonryImageWrapper = "y_jQ d_jQ d_cv";
export var galleryMasonryImage = "y_jP d_jP d_w d_bR d_dB";
export var alignLeft = "y_qh d_fp d_bG d_dv";
export var alignCenter = "y_bP d_fq d_bD d_dw";
export var alignRight = "y_qj d_fr d_bH d_dx";
export var galleryContainer = "y_t2 d_dW";
export var galleryContainerFull = "y_t3 d_dT";
export var galleryRowWrapper = "y_t4 d_cc";
export var galleryGuttersImage = "y_jS d_jS d_K d_cv";
export var galleryNoGuttersImage = "y_jR d_jR d_K d_cD";
export var galleryTextGutters = "y_jM d_jM d_cw";
export var galleryTextNoGutters = "y_jN d_jN d_cw";
export var galleryTextMasonry = "y_t5 d_jM d_cw";
export var galleryImageWrapper = "y_t6 d_fg d_Z";
export var descText = "y_t7 d_jT d_0 d_9 d_7 d_8 d_n";
export var guttersDesc = "y_t8";